<template>
  <div class="d-flex justify-content-end mb-10">
    <!--begin::Wrapper-->
    <!-- <a
      href="#"
      class="
        text-gray-400 text-hover-primary
        btn btn-sm btn-icon btn-bg-light btn-active-color-primary
        d-flex
        align-items-center
        text-gray-400 text-hover-primary
        mt-3
        mb-0
      "
      data-bs-toggle="modal"
      :data-bs-target="'#emotinal_analyze_modal' + id"
    >
      <span class="svg-icon svg-icon-2">
        <inline-svg src="/media/icons/duotune/communication/com007.svg" />
      </span>
    </a> -->
    <a
      class="
        text-gray-400 text-hover-primary
        btn btn-sm btn-icon btn-bg-light btn-active-color-primary
        d-flex
        align-items-center
        text-gray-400 text-hover-primary
        m-3
        mb-0
      "
      data-bs-toggle="modal"
      :data-bs-target="'#user_file_modal' + id"
    >
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
      <span class="svg-icon svg-icon-2">
        <inline-svg src="/media/icons/duotune/social/soc007.svg" />
      </span>
      <!--end::Svg Icon-->
    </a>
    <div class="d-flex flex-column align-items-end">
      <!--begin::Text-->
      <div
        class="
          p-5
          rounded
          bg-light-primary
          text-dark
          fw-bold
          mw-lg-1000px
          text-end
        "
        data-kt-element="message-text"
      >
        {{ text }}
      </div>
      <!--end::Text-->
      <template v-if="emotionAnalysis">
        <div class="d-flex flex-wrap mt-3 justify-content-end">
          <div class="d-flex flex-row">
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">Sinirli</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.angry }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{
                    width: emotionAnalysis.data.attributes.angry + '%',
                  }"
                  :aria-valuenow="emotionAnalysis.data.attributes.angry"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">İğrenme</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.disgust }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{
                    width: emotionAnalysis.data.attributes.disgust + '%',
                  }"
                  :aria-valuenow="emotionAnalysis.data.attributes.disgust"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">Korku</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.fear }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{ width: emotionAnalysis.data.attributes.fear + '%' }"
                  :aria-valuenow="emotionAnalysis.data.attributes.fear"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">Mutlu</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.happy }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{
                    width: emotionAnalysis.data.attributes.happy + '%',
                  }"
                  :aria-valuenow="emotionAnalysis.data.attributes.happy"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">Üzgün</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.sad }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{ width: emotionAnalysis.data.attributes.sad + '%' }"
                  :aria-valuenow="emotionAnalysis.data.attributes.sad"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">Şaşırma</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.surprise }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{
                    width: emotionAnalysis.data.attributes.surprise + '%',
                  }"
                  :aria-valuenow="emotionAnalysis.data.attributes.surprise"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div
              class="
                d-flex
                align-items-center
                w-75px w-sm-200px
                flex-column
                mt-3
              "
            >
              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                <span class="fw-bold fs-7 text-gray-400">Nötr</span>
                <span class="fw-bolder fs-7"
                  >{{ emotionAnalysis.data.attributes.neutral }}%</span
                >
              </div>
              <div class="h-5px mx-3 w-75 bg-light mb-3">
                <div
                  class="bg-success rounded h-5px"
                  role="progressbar"
                  :style="{
                    width: emotionAnalysis.data.attributes.neutral + '%',
                  }"
                  :aria-valuenow="emotionAnalysis.data.attributes.neutral"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!--end::Wrapper-->
  </div>
  <div
    class="modal fade"
    :id="'user_file_modal' + id"
    ref="changeDateRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div
          class="
            modal-body
            px-10 px-lg-15
            pt-0
            pb-15
            justify-content-center
            text-align-center
          "
        >
          <!-- Video Oynatıcı -->
          <video
            controls
            width="500"
            id="video-player"
            v-if="video && video.data && video.data.attributes"
          >
            <source
              :src="baseUploadUrl + video.data.attributes.url"
              type="video/mp4"
            />
            Tarayıcınız bu videoyu oynatmayı desteklemiyor.
          </video>

          <!-- Ses Oynatıcı -->
          <audio
            controls
            class="text-align-center"
            id="audio-player"
            v-if="voice && voice.data && voice.data.attributes"
          >
            <source
              :src="baseUploadUrl + voice.data.attributes.url"
              type="audio/mp3"
            />
            Tarayıcınız bu sesi oynatmayı desteklemiyor.
          </audio>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <div
    class="modal fade"
    :id="'emotinal_analyze_modal' + id"
    ref="changeDateRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div
          class="
            modal-body
            px-10 px-lg-15
            pt-0
            pb-15
            justify-content-center
            text-align-center
          "
        >
          <div id="chart">
            <apexchart
              type="polarArea"
              width="380"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { VoiceResponse } from "@/domain/pollster/model/voice/VoiceResponse";
import { environment } from "../../../../environment";

export default defineComponent({
  name: "message-out",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    voice: Object,
    video: Object,
    emotionAnalysis: Object,
    id: Number,
  },
  setup() {
    const baseUploadUrl = environment.baseUploadUrl;

    const chartOptions = {
      chart: {
        width: 700,
        type: "polarArea",
      },
      labels: ["A", "B", "C", "D", "E"],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      yaxis: {
        show: false,
      },
      legend: {
        position: "bottom",
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
      theme: {
        monochrome: {
          enabled: true,
          shadeTo: "light",
          shadeIntensity: 0.6,
        },
      },
    };

    const series = [42, 47, 52, 58, 65];

    return {
      chartOptions,
      series,
      baseUploadUrl,
    };
  },
});
</script>
