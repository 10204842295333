<template>
  <div class="d-flex justify-content-start mb-10">
    <!--begin::Wrapper-->
    <div class="d-flex flex-column align-items-start">
      <!--begin::Text-->
      <div v-if="loading">
        <img class="h-35px" src="/media/modules/hotel/loading.gif" />
      </div>
      <div
        v-else
        class="
          p-5
          rounded
          bg-light-info
          text-dark
          fw-bold
          mw-lg-1000px
          text-start
        "
        data-kt-element="message-text"
      >
        {{ text }}
      </div>

      <!--end::Text-->
    </div>
    <a
      href="#"
      class="
        text-gray-400 text-hover-primary
        btn btn-sm btn-icon btn-bg-light btn-active-color-primary
        d-flex
        align-items-center
        text-gray-400 text-hover-primary
        m-3
        mb-0
      "
      data-bs-toggle="modal"
      :data-bs-target="'#ai_voice_modal' + id"
    >
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
      <span class="svg-icon svg-icon-2">
        <inline-svg src="/media/icons/duotune/social/soc007.svg" />
      </span>
      <!--end::Svg Icon-->
    </a>
  </div>
  <div
    class="modal fade"
    :id="'ai_voice_modal' + id"
    ref="changeDateRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <audio controls class="text-align-center" id="audio-player" v-if="voice && voice.data && voice.data.attributes">
            <source
              :src="baseUploadUrl + voice.data.attributes.url"
              type="audio/mp3"
            />
            Tarayıcınız bu sesi oynatmayı desteklemiyor.
          </audio>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VoiceResponse } from "@/domain/pollster/model/voice/VoiceResponse";
import { environment } from "../../../../environment";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    new: String,
    loading: Boolean,
    voice: VoiceResponse,
    id: Number,
  },
  setup() {
    const baseUploadUrl = environment.baseUploadUrl;
    return {
      baseUploadUrl,
    };
  },
});
</script>
