import i18n from "@/presentation/language/i18n";

export const timeAgo = (isoDate) => {
    const date = new Date(isoDate);
    const now = new Date();

    const difference = now.getTime() - date.getTime();

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    if (days === 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60));
      if (hours === 0) {
        const minutes = Math.floor(difference / (1000 * 60));
        return `${minutes} ` + i18n.global.t("common.times.minuteAgo");
      } else {
        return `${hours} ` + i18n.global.t("common.times.hourAgo");
      }
    } else {
      return `${days} ` + i18n.global.t("common.times.dayAgo");
    }
  };

  export const dateToIsoDate = (date) => {
    return date.toISOString().split("T")[0]; // YYYY-MM-DD formatında döndür
  };

  export const formatFullDateWithTime = (dateString) => {
    if (!dateString) return ""; // Eğer tarih boşsa, boş string döndür
  
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthName = date.toLocaleString("tr-TR", { month: "long" }); // Türkçe ay ismi
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
  
    return `${day} ${monthName} ${year}, ${hours}:${minutes}`; // Örn: 14 Aralık 2022, 18:43
  };


  export const formatLocalizedDate = (dateString, locale = "tr-TR") => {
    if (!dateString) return ""; // Eğer tarih boşsa, boş string döndür
    return new Intl.DateTimeFormat(locale).format(new Date(dateString));
  };


  export const formatShortDate = (dateString) => {
    if (!dateString) return ""; // Eğer tarih boşsa, boş string döndür
  
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`; // gg-aa-yyyy formatında döndür
  };

  export const convertSecondsToTime  = (seconds: number) => {
      const minutes = Math.floor(seconds / 60); 
      const remainingSeconds = seconds % 60; 

      return `${minutes} dakika ${remainingSeconds} saniye`;
  }